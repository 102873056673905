<template>
    <v-card>
        <v-card-title>Trap Details: {{ trapId }}</v-card-title>
        <v-card-text v-if="trap">
            <v-simple-table>
                <tbody>
                <tr-title title="Request"/>
                <tr-pair name="Method / Path">
                    <span class="subtitle-1 font-weight-black">{{ trap.requestMethod }} {{ trap.requestPath }}</span>
                </tr-pair>

                <tr-pair name="Request Date Time">{{ trap.requestDateTime|AsDateTime }}</tr-pair>

                <tr-title :title="`Query Params (${queryCount})`" v-if="hasQueryParams">
                    <div class="d-flex"><v-spacer/>
                        <v-btn @click="queriesOpen = !queriesOpen" icon><v-icon>{{ queriesOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon></v-btn></div>
                </tr-title>
                <tr-pair-list v-show="queriesOpen" :name="query.Key" :values="query.Value" v-for="query in trap.requestQuery" :key="query.Key"></tr-pair-list>

                <tr-title :title="`Request headers (${headerCount})`">
                    <div class="d-flex"><v-spacer/>
                        <v-btn @click="headersOpen = !headersOpen" icon><v-icon>{{ headersOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon></v-btn></div>
                </tr-title>
                <tr-pair-list v-show="headersOpen" :name="header" :values="value" v-for="(value, header) in trap.requestHeaders" :key="header"/>

                <tr-title v-if="hasBody" title="Body" />
                <tr-pair name="" v-if="hasBody">
                    <pre>{{ trap.requestBody }}</pre>
                </tr-pair>
                </tbody>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import APITraps from "@/api/APITraps";
import TrPair from "@/components/tr-pair.vue";
import TrPairList from "@/components/tr-pair-list.vue";
import TrTitle from "@/components/tr-title.vue";

export default {
    name: "TrapDetail",
    components: {TrTitle, TrPair, TrPairList},
    mounted() {
    },
    watch: {
        trapId: {
            immediate: true,
            handler() {
                APITraps.get(this.trapId).then((response) => {
                    this.trap = response;
                });

            }
        }
    },
    props: {
        trapId: {
            type: String,
            required: true,
        }
    },
    data: () => ({
        headersOpen: false,
        trap: null,
        queriesOpen: false
    }),
    computed: {
        hasBody() {
            return !!(this.trap.requestBody)
        },
        hasQueryParams() {
            return this.queryCount > 0;
        },
        queryCount() {
            return this.trap.requestQuery.length;
        },
        headerCount() {
            return Object.keys(this.trap.requestHeaders).length;
        },
        trapSimple() {
            let x = {...this.trap};
            x.requestHeaders = null;
            x.requestMethod = null;
            x.requestPath = null;
            return x;
        }
    }
}
</script>

<style scoped>

</style>
