<template>
    <v-container>
        <v-simple-table fixed-header>
            <thead>
            <tr>
                <td>When</td>
                <td>Action</td>
                <td>Id</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="trap in traps" :key="trap.id">
                <td class="grey--text">{{ trap.requestDateTime|AsDateTime }}</td>
                <td>{{ trap.requestMethod }} {{ trap.requestPath }}</td>
                <td class="grey--text">{{ trap.id }}</td>
                <td class="text-right">
                    <v-btn x-small text @click="showTrap(trap.id)">View Details</v-btn>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
        <div v-if="traps.length === 0" class="caption font-weight-black text-center grey--text">No data available</div>
        <v-dialog max-width="1200" v-model="showTrapDialog" v-if="trapId">
            <TrapDetail v-if="trapId" :trapId="trapId" @close="hideTrap"/>
        </v-dialog>
    </v-container>
</template>

<script>
import APITraps from "@/api/APITraps";
import TrapDetail from "@/components/TrapDetail.vue";

export default {
    name: 'TrapList',
    components: {TrapDetail},

    data: () => ({
        traps: [],
        trapId: null,
        showTrapDialog: false
    }),
    mounted() {
        this.reload();
        this.$bus.$on('refresh', this.reload);
    },
    methods: {
        hideTrap() {
            this.showTrapDialog = false;
            this.trapId = null
        },
        showTrap(id) {
            this.trapId = id;
            this.showTrapDialog = true;
        },
        reload() {
            this.$bus.$emit('loading', true);
            APITraps.getList().then((response) => {
                this.traps = response;
            }).finally(() => {
                this.$bus.$emit('loading', false);
            });
        }

    }
}
</script>

<style scoped>

td:first-child {
    width: 200px;
}

td:nth-last-child(2) {
    width: 300px;
}

td:last-child {
    width: 200px;
}

</style>
