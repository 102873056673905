<template>
<tr>
    <td class="text-no-wrap"><span class="ml-4">{{ name }}</span></td>
    <td class="pa-0">
        <v-simple-table class="ma-0">
            <tbody>
            <tr v-for="(value, idx) in values" :key="`tr-${idx}`">
                <td>{{ value}}</td>
            </tr>
            </tbody>
        </v-simple-table>
        </td>
</tr>
</template>

<script>
export default {
    name: "tr-pair",
    props: {
        name: {
            type: String,
            required: true
        },
        values: {
            type: Array,
            required: true
        }
    }
}
</script>
