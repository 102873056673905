const CODE = encodeURI('N7m6kQyuLMD5gF3nLYcGABtVvYXIbBZFkurPBeWNdiS9AzFuIFIcMg==');

function getList() {
    return fetch('https://traffictrapper.azurewebsites.net/api/traps?code=' + CODE)
        .then(response => response.json())
}

function deleteAll() {

    return fetch('https://traffictrapper.azurewebsites.net/api/traps?code=' + CODE, {
        method: 'DELETE',
    })
        .then(response => response.json())
}
function get(id) {
    return fetch('https://traffictrapper.azurewebsites.net/api/trap/' + encodeURI(id))
        .then(response => response.json())
}

export default {
    deleteAll,
    getList,
    get
}
