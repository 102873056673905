<template>
    <tr>
        <td class="text-no-wrap"><span class="ml-4">{{ name }}</span></td>
        <td>
            <slot></slot>
        </td>
    </tr>
</template>

<script>
export default {
    name: "tr-pair",
    props: {
        name: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped>

</style>
