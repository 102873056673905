import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.prototype.$bus = new Vue();

Vue.filter('AsDateTime', function (value) {

  var d = new Date(value);
  return d.toLocaleString(navigator.language);

});

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
