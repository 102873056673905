<template>
<tr>
    <td class="subtitle-1 font-weight-light">{{ title }}</td>
    <td><slot/></td>
</tr>
</template>

<script>
export default {
    name: "tr-title",
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
