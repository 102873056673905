<template>
    <v-app>
        <v-app-bar app color="gray darken-1" dark>
            <div class="d-flex align-center">
                <v-avatar size="48">
                    <v-img src="/android-chrome-192x192.png"/>
                </v-avatar>
                <div class="ml-4 headline font-weight-light">traffic trapper</div>
            </div>
            <v-spacer/>
            <div class="d-flex align-center">
                <v-tooltip bottom open-delay="800">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon :loading="deleting" :disabled="loading" class="mr-4" @click="deleteRUSure" v-on="on" v-bind="attrs">
                        <v-icon>mdi-delete-empty-outline</v-icon>
                        </v-btn>
                    </template>
                    <div>Clear</div>
                </v-tooltip>
                <v-tooltip bottom open-delay="800">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon :loading="loading" :disabled="deleting" class="mr-4" @click="$bus.$emit('refresh')" v-on="on" v-bind="attrs">
                        <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </template>
                    <div>Reload</div>
                </v-tooltip>
                <v-tooltip bottom open-delay="800">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon @click="help = !help" v-on="on" v-bind="attrs">
                            <v-icon>mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </template>
                    <div>Help</div>
                </v-tooltip>
            </div>
        </v-app-bar>

        <v-main>
            <TrapList/>
        </v-main>

        <v-dialog v-model="help" width="800">
            <v-card>
                <v-card-title  class="headline warning white--text">Help</v-card-title>
                <v-card-text class="pa-4">
                    <p>In order to trap traffic point traffic at
                        <v-tooltip bottom>
                            <template v-slot:activator="{on, attrs}">
                                <span v-on="on" v-bind="attrs" @click="copyUrl" class="copyable orange--text">https://traffictrapper.azurewebsites.net/api/capture <v-icon small>mdi-content-copy</v-icon></span>
                            </template>
                            <span>{{  copyMessage }}</span>
                        </v-tooltip>
                        to see it captured and displayed here.</p>
                </v-card-text>
                <v-card-actions class="text-right">
                    <v-btn @click="help = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
       <v-dialog v-model="askDelete" width="400">
            <v-card>
                <v-card-title class="headline warning white--text">Clear</v-card-title>
                <v-card-text class="pa-4 text-center">
                    <v-icon size="128" color="warning" class="my-4">mdi-alert-circle-outline</v-icon>
                    <p>Clear the trap history, are you sure?</p>
                </v-card-text>
                <v-card-actions class="justify-center pb-6">
                    <v-btn @click="askDelete = false" class="mr-2">Cancel</v-btn>
                    <v-btn color="warning" @click="doDelete">CLEAR HISTORY</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import TrapList from './components/TrapList';
import APITraps from "@/api/APITraps";

export default {
    name: 'App',

    components: {
        TrapList,
    },
    mounted() {
        this.$bus.$on('loading', (loading) => {
            this.loading = loading;
        });
    },
    methods: {
        async copyUrl() {
            try {
                await navigator.clipboard.writeText(this.uri);
                console.log('Uri copied to clipboard');
                this.showCopied = true
                window.setTimeout(() => {
                    this.showCopied = false
                }, 2000);
            } catch {
                // Do nothing
            }
        },
        doDelete() {
            this.askDelete = false;
            this.deleting = true;
            APITraps.deleteAll()
                .finally(() => {
                    this.deleting = false;
                    this.$bus.$emit('refresh');
                });
        },
        deleteRUSure() {
            this.askDelete = true;
        }
    },
    data: () => ({
        askDelete: false,
        help: false,
        loading: false,
        deleting: false,
        uri: 'https://traffictrapper.azurewebsites.net/api/capture',
        showCopied: false
    }),
    computed: {
        copyMessage() {
            return this.showCopied ? 'Copied' : 'Click to copy';
        }
    }
};
</script>

<style>
.copyable {
    cursor: copy;
}
</style>
